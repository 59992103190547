//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      listData:[],
      icp: '京ICP备13010625号-13', // biaoju01.com: 京ICP备13010625号-13, biaoju.cn:蜀ICP备18024402号-5
      year: new Date().getFullYear()
    };
  },
  mounted() {
    const url = window.location.href; // 获取当前页面的URL
    if(url.indexOf('biaoju.cn') > -1) {
      this.icp = '蜀ICP备18024402号-5'
    } else {
      this.icp = '京ICP备13010625号-13'
    }
    this.$axios.get("/api/init/trademarkIndexInit").then(res => {
      this.listData=res.data.data
    })
  },

};
